import { useState } from "react";
import { Row, Col, Tabs, Tab } from "react-bootstrap";
import BOK from "./BOK";

function BOKStatsRankComponent(props) {
    const stats = ["HP", "SP", "ATK", "sATK", "DEF", "sDEF", "SPD"];
    const monsters = BOK.Monster.getAll();
    const [hoveredMon, setHoveredMon] = useState(null);

    /**
     * Renders a column for the top 10 monsters for a specific stat or the sum of all stats.
     * @param {string} stat - The stat to rank by, or "ALL" for the sum of all stats.
     * @param {boolean} reverse - Whether to reverse the sorting.
     */
    const renderTop10Column = (stat, reverse) => {
        const sortedMonsters =
            stat === "ALL"
                ? sortByTotalStats(monsters, stats, reverse)
                : sortByStat(monsters, stat, reverse);

        const bgOpacity = stat === "ALL" ? 0.85 : 0.75;

        return (
            <Col
                className="border border-info bg-dark text-white rounded"
                style={{ "--bs-bg-opacity": bgOpacity }}
            >
                <div className="text-center text-secondary fw-bold mb-2">{stat}</div>
                {sortedMonsters.slice(0, 10).map((mon) => (
                    <div
                        key={mon.guid}
                        className={`d-flex align-items-center justify-content-start mb-1 ${
                            hoveredMon === mon.guid ? "highlighted-mon" : ""
                        }`}
                        onMouseEnter={() => setHoveredMon(mon.guid)}
                        onMouseLeave={() => setHoveredMon(null)}
                    >
                        <img
                            className="pixelated me-1"
                            src={mon.Image.Portrait}
                            alt={mon.id}
                            width="30"
                            height="30"
                            style={{ fontSize: "14px" }}
                        />
                        <span style={{ fontSize: "14px" }}>
                            {stat === "ALL"
                                ? calculateTotalStats(mon, stats)
                                : mon.Stats[stat]}
                        </span>
                    </div>
                ))}
            </Col>
        );
    };

    return (
        <>
            <h5 className="text-center bg-success text-white py-3">Top 10 Base Stats</h5>
            <Tabs
                defaultActiveKey="best"
                id="top-10-tabs"
                className="full-width-tabs text-white"
                justify
            >
                <Tab eventKey="best" title="Best">
                    <div className="p-1">
                        <Row className="gx-1">
                            {stats.map((stat) => renderTop10Column(stat, false))}
                            {renderTop10Column("ALL", false)}
                        </Row>
                    </div>
                </Tab>
                <Tab eventKey="worst" title="Worst">
                    <div className="p-1">
                        <Row className="gx-1">
                            {stats.map((stat) => renderTop10Column(stat, true))}
                            {renderTop10Column("ALL", true)}
                        </Row>
                    </div>
                </Tab>
            </Tabs>
        </>
    );
}

/**
 * Sorts the database by the specified stat.
 * @param {Array} db - The array of monsters.
 * @param {string} stat - The stat to sort by (e.g., "HP", "SP", "ATK", "sATK", "DEF", "sDEF", "SPD").
 * @param {boolean} reverse - If true, sorts in ascending order; otherwise, sorts in descending order.
 * @returns {Array} - The sorted array.
 */
function sortByStat(db, stat, reverse = false) {
    return [...db].sort((a, b) => {
        return reverse
            ? a.Stats[stat] - b.Stats[stat] // Ascending order
            : b.Stats[stat] - a.Stats[stat]; // Descending order
    });
}

/**
 * Sorts the database by the total of all stats.
 * @param {Array} db - The array of monsters.
 * @param {Array} stats - The list of stats to sum.
 * @param {boolean} reverse - If true, sorts in ascending order; otherwise, sorts in descending order.
 * @returns {Array} - The sorted array.
 */
function sortByTotalStats(db, stats, reverse = false) {
    return [...db].sort((a, b) => {
        const totalA = calculateTotalStats(a, stats);
        const totalB = calculateTotalStats(b, stats);
        return reverse ? totalA - totalB : totalB - totalA;
    });
}

/**
 * Calculates the total of all stats for a monster.
 * @param {Object} monster - The monster object.
 * @param {Array} stats - The list of stats to sum.
 * @returns {number} - The total sum of all stats.
 */
function calculateTotalStats(monster, stats) {
    return stats.reduce((total, stat) => total + (monster.Stats[stat] || 0), 0);
}

export default BOKStatsRankComponent;
